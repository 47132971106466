@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body, #root {
    @apply h-full;
  }

  body {
    @apply bg-white;
  }

  a {
    @apply text-black;
  }
}